<template>
  <div id="app antialiased">
    <banner phone-number="332-330-4248" :on-click="scheduleAppt" />
    <section-header :on-click="scheduleAppt" />
    <main>
      <section-hero
        video-webm="https://s3.us-east-1.amazonaws.com/static.bigleaphealth.com/mrhs/lost-in-thought.webm"
        video-mp4="https://s3.us-east-1.amazonaws.com/static.bigleaphealth.com/mrhs/lost-in-thought.mp4"
        poster="https://s3.us-east-1.amazonaws.com/static.bigleaphealth.com/mrhs/psychology-still.webp"
        header="Free yourself from Depression & Anxiety"
        lead="Medically supervised TMS & Esketamine treatments for sustained relief in a serene and safe environment."
        :on-click="scheduleAppt"
      />
      <section-insurance />
      <delay-hydration>
        <section-doctor />
        <section-how-it-works />
        <section-testimonials :testimonials="testimonials" />
        <section-treatments :treatments="treatments" />
        <section-clinics />
        <section-faq />
        <org-video />
        <section-benchmarks />
        <section-disclosures />
        <section-footer />
      </delay-hydration>
    </main>
  </div>
</template>

<script setup>
import { withQuery } from "ufo";

import KetamineInfusionIcon from "~/assets/images/icons/ketamine-infusion.svg";
import RegenerativeHealthIcon from "~/assets/images/icons/regenerative-health.svg";
import StellateGanglionBlockIcon from "~/assets/images/icons/stellate-ganglion-block.svg";
import TranscranialMagneticStimulationIcon from "~/assets/images/icons/transcranial-magnetic-stimulation.svg";

const { gtag } = useGtag();
const route = useRoute();
const url = withQuery("https://un6a7rqgzdf.typeform.com/to/fSrMTF0k", {
  ...{
    utm_source: "xxxxx",
    utm_medium: "xxxxx",
    utm_campaign: "xxxxx",
    utm_term: "xxxxx",
    utm_content: "xxxxx",
  },
  ...route.query,
});
useSeoMeta({
  title: "Manhattan Restorative Health Sciences | Psychiatry",
  ogTitle: "Manhattan Restorative Health Sciences | Psychiatry",
  description:
    "MRHS, led by Dr. Tim Canty, offers innovative 'body and mind' treatments and groundbreaking medical research in Manhattan.",
  ogDescription:
    "MRHS, led by Dr. Tim Canty, offers innovative 'body and mind' treatments and groundbreaking medical research in Manhattan.",
});

const scheduleAppt = () => {
  gtag("event", "schedule_appointment", {
    event_category: "lead",
  });
  navigateTo(url, {
    external: true,
    open: {
      target: "_blank",
    },
  });
};

const testimonials = [
  {
    name: "Kelly M, age 29",
    job: "Dental Hygienist",
    quote:
      "The team and Dr. Canty were very empathetic and put you right at ease. Everyone could not do enough to make it easy for you and everything was explained perfectly to make you feel comfortable.",
    image: "/images/claudia@2x.png",
  },
  {
    name: "Candace O, age 24",
    job: "Student",
    quote:
      "I was very impressed with the professionalism and care I received from Dr. Canty and his staff. I would highly recommend them to anyone.",
    image: "/images/candace@2x.png",
  },
];

const treatments = [
  {
    name: "Stellate Ganglion Block",
    icon: StellateGanglionBlockIcon,
  },
  {
    name: "Transcranial Magnetic Stimulation",
    icon: TranscranialMagneticStimulationIcon,
  },
  {
    name: "Regenerative Health",
    icon: RegenerativeHealthIcon,
  },

  {
    name: "Esketamine & Infusions",
    icon: KetamineInfusionIcon,
  },
];
</script>
